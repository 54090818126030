import { useState, useEffect } from 'react';

function Schedule (props) {

    const { innerRef } = props;

    const [error, setError] = useState(null);

    const apiKey = "AIzaSyAfLyGB6uGBbhUOGhOXcUiTemyTtj6MKD4";
    const sheetId = "1qf0ZUH_2lJiHYl9VWXbnoXhhIwALmNBYqM7KMFjljOo";
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/?key=${apiKey}&includeGridData=true`;

    const [events, setEvents] = useState([]);

    useEffect(() => {
        
        const fetchData = async () => {
            try {
                const response = await fetch(url);
    
                if (!response.ok) {
                    if (response.status === 429) {
                        throw new Error("Please wait 30 seconds and refresh the page, or checkout our Facebook page!")
                    }
                    else {
                        throw new Error("Something went wrong.  Please check out our Facebook page in the contact section for the schedule!");
                    }
                }
                const jsonData = await response.json();
                let currEvents = []
                const entries = jsonData.sheets[0].data[0].rowData;
                for (let entry = 1; entry < entries.length; entry++) {
                    if (entries[entry].values[0].formattedValue) {
                        let event = {
                            date : entries[entry].values[0]?.formattedValue,
                            time : entries[entry].values[1]?.formattedValue,
                            event: entries[entry].values[2]?.formattedValue,
                            city: entries[entry].values[3]?.formattedValue,
                            id: entry,
                        }
                    currEvents.push(event);
                    }
                }
                setEvents(currEvents);
            } catch (err) {
                setError(err.message);
            }
        };
    
        fetchData();
      }, [url]);

    return <>
        <section ref={innerRef} id="schedule" className="section">
            <div className="section-backdrop right">               
                <h1 className="section-title">Schedule</h1>
                {
                    error == null ? 
                    events.map((event) => {
                        return <div key={event.id}>{event.date} {event.time}<br />{event.event} {event.index}<br />{event.city}<br /><br /></div>
                    }) 
                    : 
                    <div>{error}</div>
                }
            </div>
        </section>
    </>
}

export default Schedule;